import React from 'react';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import './bragi.scss';
import ReactPlayer from 'react-player';

class Bragi extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    const { fromLoki } = this.props;
    const { isOpen } = this.state;
    if (fromLoki) this.setState({ isOpen: !isOpen });
  }

  urlToId = url => {
    if (url) return url.replace('https://www.youtube.com/watch?v=', '');
  };

  onCloseModal = () => {
    const { fromLoki, closeModal } = this.props;
    this.setState({ isOpen: false }, () => {
      if (fromLoki) closeModal();
    });
  };

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { url } = this.props;
    if (url) {
      return (
        <div className="bragi-container">
          <ReactPlayer
            className="react-player"
            url={url}
            playing={false}
            width="100%"
            height="100%"
            preload
            volume={1}
            muted
            controls
          />
        </div>
      );
    }
    return null;
  }
}

export default Bragi;
