import React, { Component } from 'react';

import MediaQuery from 'react-responsive';

import Loki from './Loki';
import './loki.scss';

class LokiPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = this.props;
    return (
      <div className="top-paw-body">
        <div className="slider-container">
          <MediaQuery minWidth={320} maxWidth={425}>
            <Loki slides={1.0} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={426} maxWidth={459}>
            <Loki slides={1.2} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={460} maxWidth={505}>
            <Loki slides={1.3} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={506} maxWidth={539}>
            <Loki slides={1.4} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={540} maxWidth={569}>
            <Loki slides={1.5} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={570} maxWidth={611}>
            <Loki slides={1.6} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={610} maxWidth={689}>
            <Loki slides={1.7} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={690} maxWidth={769}>
            <Loki slides={1.9} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={770} maxWidth={827}>
            <Loki slides={2.1} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={828} maxWidth={908}>
            <Loki slides={2.2} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={909} maxWidth={967}>
            <Loki slides={2.4} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={968} maxWidth={1023}>
            <Loki slides={2.6} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1024} maxWidth={1100}>
            <Loki slides={2.8} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1101} maxWidth={1120}>
            <Loki slides={2.9} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1121} maxWidth={1165}>
            <Loki slides={3.1} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1166} maxWidth={1240}>
            <Loki slides={3.2} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1241} maxWidth={1270}>
            <Loki slides={3.4} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1271} maxWidth={1349}>
            <Loki slides={3.5} dots={false} data={data} />
          </MediaQuery>
          <MediaQuery minWidth={1350}>
            <Loki slides={3.7} dots={false} data={data} />
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default LokiPanel;
