import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Buttons from '../Buttons/Buttons';
import './modi.scss';
import tt from '../../helpers/translation';

const Modi = injectIntl(props => {
  const {
    data: {
      Image,
      title,
      Text,
      Text2,
      button1,
      button2,
      button3,
      classContainer,
      link,
      colorRound,
      contactText,
      altImage,
    },
    intl,
  } = props;

  const [showBox, setshowBox] = useState(false);

  const toggleBox = () => {
    setshowBox(!showBox);
  };

  let isSecondButton;
  if (button2) {
    isSecondButton = (
      <Buttons
        link={button2.cta}
        size={button2.size}
        color={button2.color}
        text={button2.ctaText}
      />
    );
  }

  let isThirdButton;
  if (button3) {
    isThirdButton = (
      <Buttons
        link={button3.cta}
        size={"margin_top"}
        color={button3.color}
        text={button3.ctaText}
      />
    );
  }
  let hasTitle;

  if (title) {
    hasTitle = <div className="title-container" dangerouslySetInnerHTML={{ __html: title }} />;
  }

  let hasSecondText;

  if (Text2) {
    hasSecondText = <span className="secondary-text">{Text2}</span>;
  }

  let hasContactText;

  if (contactText) {
    hasContactText = <span className="contact-text">{contactText}</span>;
  }

  let hasLink;

  if (link) {
    hasLink = (
      <>
        <div
          className={`second-text-hidden ${!showBox ? '' : 'nohidden'}`}
          dangerouslySetInnerHTML={{ __html: link }}
        />
        <div className="seemore" onClick={toggleBox}>{`${
          !showBox ? tt('Leer más', intl.locale) : tt('Leer menos', intl.locale)
        }`}</div>
      </>
    );
  }

  let hasImage;
  if (Image) {
    hasImage = (
      <div className={`modiCard-image ${colorRound}`}>
        <img src={Image} alt={altImage || 'logo'} />
      </div>
    );
  }

  return (
    <div className="modiContainer-main">
      <div className="modi-wrapper">
        <div className={`modiCard-main ${classContainer}`}>
          {hasImage}
          <div className="modiCard-text">
            {hasTitle}
            <div className="primary-text" dangerouslySetInnerHTML={{ __html: Text }} />
          </div>

          {hasSecondText}
          {hasLink}

          <div className="level">
            {button1 && (
              <Buttons
                link={button1.cta}
                size={button1.size}
                color={button1.color}
                text={button1.ctaText}
              />
            )}
            {isSecondButton}
            {isThirdButton}
          </div>
          {hasContactText}
        </div>
      </div>
    </div>
  );
});

/* eslint-disable react/require-default-props */
Modi.propTypes = {
  data: PropTypes.shape({
    Image: PropTypes.string,
    Text: PropTypes.string,
    Text2: PropTypes.string,
    button1: PropTypes.object,
    button2: PropTypes.object,
    button3: PropTypes.object,
    classContainer: PropTypes.string,
    contactText: PropTypes.string,
  }),
};
/* eslint-enable react/require-default-props */

export default Modi;
