import './loki.scss';

import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import Slider from 'react-slick';
import Icon from '../../helpers/icon';
import Bragi from '../Bragi/bragi';
import lokiSet from './loki-icons';

const SamplePrevArrow = (properties) => {
  const { className, style, onClick } = properties;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        left: '20px',
        background: 'white',
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" width="35" height="35" class="icon-arrow" iconSet={lokiSet} />
    </div>
  );
};

const SampleNextArrow = (properties) => {
  const { className, style, onClick } = properties;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        zIndex: '100',
        right: '20px',
        background: 'white',
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" width="35" height="35" class="icon-arrow" iconSet={lokiSet} />
    </div>
  );
};

class Loki extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      element: null,
      isBragi: null,
    };
  }

  mapSliderCarrusel = (array) =>
    array.map((element, index) => (
      <div>
        <div key={element.img} className="loki-card mobile">
          <Img fluid={element.img} alt={element.altImage} />
        </div>
        <div
          key={element.img}
          className="loki-card desktop"
          onClick={() => this.onClick(index, false)}
        >
          <Img fluid={element.img} alt={element.altImage} />
        </div>
      </div>
    ));

  mapSlider = (array) =>
    array.map((element, index) => (
      <div
        key={element.img}
        className="loki-card fullSize"
        onClick={() => this.onClick(index, false)}
      >
        <Img
          fluid={element.img}
          alt={element.altImage}
          style={{ height: `calc(70% * 1/${element.img.aspectRatio})` }}
        />
      </div>
    ));

  onClick = (element, isBragi) => {
    this.setState({
      element,
      isBragi,
      showModal: true,
    });
  };

  handleChange = () => {
    this.setState({
      showModal: false,
    });
  };

  prepareForLoki = (images) => {
    const imageArr = this.mapSliderCarrusel(images);
    if (this.props.data.data.video) {
      const videoArray = this.props.data.data.video.map((item, index) => (
        <div onClick={() => this.onClick(index, true)} className="loki-card">
          <Bragi thumbNail={item.node.localImage.childImageSharp.fluid} url={item.node.video} />
        </div>
      ));
      return [...videoArray, ...imageArr];
    }
    return imageArr;
  };

  render() {
    const { dots, slides, data } = this.props;
    const { element, isBragi, showModal } = this.state;

    const images = data.data.arrayImg;
    const bigImages = data.data.bigArrayImg;
    const settings = {
      centerMode: false,
      infinite: false,
      centerPadding: '60px',
      slidesToShow: slides,
      speed: 500,
      dots: true,
      nextArrow: dots ? '' : <SampleNextArrow />,
      prevArrow: dots ? '' : <SamplePrevArrow />,
    };
    const settingsInfinite = {
      centerMode: false,
      infinite: true,
      centerPadding: '60px',
      slidesToShow: slides,
      speed: 500,
      dots: true,
      nextArrow: dots ? '' : <SampleNextArrow />,
      prevArrow: dots ? '' : <SamplePrevArrow />,
    };

    const settingsInfinite2 = {
      className: 'slider_setting',
      initialSlide: element,
      centerMode: true,
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      nextArrow: dots ? '' : <SampleNextArrow />,
      prevArrow: dots ? '' : <SamplePrevArrow />,
    };

    if (images.length > 1) {
      if (images.length >= 4) {
        return (
          <div className="loki-container">
            <div className="loki-galery">
              <Slider {...settingsInfinite}>{this.mapSliderCarrusel(images)}</Slider>
              <div className="loki-slider-transparent" />
            </div>
            {showModal && (
              <div className="modal is-active">
                <div className="modal-background" tabIndex="0" onClick={this.handleChange} />
                <button
                  className="modal-close is-large"
                  aria-label="close"
                  onClick={this.handleChange}
                />
                <div className="modal-content">
                  {isBragi === false ? (
                    <div className="slider-desktop">
                      <Slider {...settingsInfinite2}>{this.mapSlider(bigImages)}</Slider>
                    </div>
                  ) : (
                    <Bragi
                      closeModal={this.handleChange}
                      fromLoki
                      url={this.props.data.data.video[this.state.element].node.video}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }
      return (
        <div className="loki-container">
          <div className="loki-galery">
            <Slider {...settings}>{this.mapSliderCarrusel(images)}</Slider>
            <div className="loki-slider-transparent" />
          </div>
          {showModal && (
            <div className="modal is-active">
              <div className="modal-background" tabIndex="0" onClick={this.handleChange} />
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={this.handleChange}
              />
              <div className="modal-content">
                {isBragi === false ? (
                  <div className="slider-desktop">
                    <Slider {...settingsInfinite2}>{this.mapSlider(bigImages)}</Slider>
                  </div>
                ) : (
                  <Bragi
                    closeModal={this.handleChange}
                    fromLoki
                    url={this.props.data.data.video[this.state.element].node.video}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
    if (images.length === 0) return null;

    return (
      <div className="alon-image-container">
        <Img fluid={bigImages[0].img} alt={bigImages[0].altImage} />
      </div>
    );
  }
}
export default Loki;
