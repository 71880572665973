import { graphql } from 'gatsby';
import React from 'react';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import { Fenrir } from '../src/components/Fenrir/Fenrir';
import { Freya } from '../src/components/Freya/Freya';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import Loki from '../src/components/Loki/lokipane';
import Modi from '../src/components/Modi/Modi';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Ymir from '../src/components/Ymir/Ymir';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './ficha_wellness.scss';

class FichaWellness extends React.Component {
  state = {};

  prepareForYmir = () => {
    const dc = this.props.data.allAdditionalLinks3.edges[0].node.cards;
    const ret2 = [
      {
        title: dc._0.title,
        buttonCard: dc._0.buttonCard,
        link: dc._0.link,
        icon: dc._0.icon,
      },
      {
        title: dc._1.title,
        buttonCard: dc._1.buttonCard,
        link: dc._1.link,
        icon: dc._1.icon,
      },
      {
        title: dc._2.title,
        buttonCard: dc._2.buttonCard,
        link: dc._2.link,
        icon: dc._2.icon,
      },
    ];
    return ret2;
  };

  render() {
    const heimdallData = {
      name: this.props.data.allWellnessTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allWellnessBlockImageInfoGeneral.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const modiData = {
      Image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/047/original/PortAventura_Hotels_Brand_Master.png',
      Text: this.props.data.allWellnessTitleBlock.edges[0].node.description,
      button1: {
        cta: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        size: 'alone',
        color: 'orange',
        ctaText: tt('Reservar Ahora', this.props.pageContext.locale),
      },
    };
    const modiDataMobile = {
      Image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/047/original/PortAventura_Hotels_Brand_Master.png',
      Text: this.props.data.allWellnessTitleBlock.edges[0].node.description,
      button1: {
        cta: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        size: 'alone',
        color: 'orange',
        ctaText: tt('Reservar Ahora', this.props.pageContext.locale),
      },
    };
    const isMobileModi = <Modi data={modiDataMobile} />;
    const isDesktopModi = <Modi data={modiData} />;

    const h2Data = {
      title: this.props.data.allWellnessDescriptionSubBlocks.edges[0].node.title,
      description: this.props.data.allWellnessDescriptionSubBlocks.edges[0].node.summary,
    };

    const ymirData = {
      cards: this.prepareForYmir(),
    };

    const fenrirData = {
      list: this.props.data.allMultiColumnWellness.edges[0].node.body_left,
      title: this.props.data.allMultiColumnWellness.edges[0].node.title,
      listTitle: this.props.data.allMultiColumnWellness.edges[0].node.title,
    };

    const lokiData = {
      title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
      arrayImg: this.props.data.allWellnessSliderGallerySubBlock.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter((item) => item.img !== null),

      bigArrayImg: this.props.data.bigLokiPics.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter((item) => item.img !== null),
    };
    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allWellnessPromoted.edges.map((well) => ({
        image: well.node.localImage.childImageSharp.fluid,
        altImage: well.node.image_alt,
        title: well.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: well.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: well.node.ctaText,
        },
      })),
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allWellnessSeoData.edges[0].node._0.title}
          description={this.props.data.allWellnessSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="wellness-subpage-content">
              <MediaServerRender predicted="mobile" hydrated>
                <MediaMatcher mobile={isMobileModi} tablet={isMobileModi} desktop={isDesktopModi} />
              </MediaServerRender>
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allFichaWellnessBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allFichaWellnessRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allWellnessBlockImageInfoGeneral.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <BlueArrow />

              <div className="wellness-text-content">
                <H2 data={h2Data} />
                <Loki data={lokiData} />
                <H3 data={fenrirData} />
                <Fenrir data={fenrirData} />
              </div>
              <Ymir data={ymirData} />
              <div className="promotions-content">
                <H4 title="otros hoteles" />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
FichaWellness.propTypes = {};
/* eslint-enable react/require-default-props */

export default FichaWellness;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allFichaWellness($name: String!, $locale: String!) {
    allWellnessTitleBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          title
          subTitle
          description
        }
      }
    }
    allWellnessSeoData(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allWellnessBlockImageInfoGeneral(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          call_to_action_mobile_copy
        }
      }
    }
    allWellnessPromoted(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          cta
          ctaText
          tag
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }

    allWellnessSliderGallerySubBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }

    bigLokiPics: allWellnessSliderGallerySubBlock(
      filter: { tag: { eq: $name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }

    allMultiColumnWellness(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          body_left
        }
      }
    }
    allAdditionalLinks3(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          cards {
            _0 {
              title
              buttonCard
              link
              icon
            }
            _1 {
              title
              buttonCard
              link
              icon
            }
            _2 {
              title
              buttonCard
              link
              icon
            }
          }
        }
      }
    }
    allWellnessDescriptionSubBlocks(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          summary
        }
      }
    }
    allFichaWellnessBreadCrumbBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaWellnessRichData(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
