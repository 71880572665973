import './TitleH2.scss';

import PropTypes from 'prop-types';
import React from 'react';

const TitleH2 = props => {
  const {
    data: { title, description, children, colorTitle },
  } = props;
  let isColorTitle;
  if (colorTitle) {
    isColorTitle = (
      <h2 className={`TitleH2-title ${colorTitle}`} dangerouslySetInnerHTML={{ __html: title }} />
    );
  } else if (title) {
    isColorTitle = <h2 className="TitleH2-title" dangerouslySetInnerHTML={{ __html: title }} />;
  }

  return (
    <div className="TitleH2-container">
      {isColorTitle}
      {description ? (
        <div className="TitleH2-subtitle" dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {children}
    </div>
  );
};

TitleH2.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TitleH2;
